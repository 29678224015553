/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from '../../Input';
import CheckBox from '../../CheckBox';

import save from '../../../assets/save.svg';
import chevron from '../../../assets/chevron.svg';
import loading from '../../../assets/loading03.svg';
import pt from '../../../assets/pt.svg';
import uk from '../../../assets/uk.svg';

import {
  Container,
  Modal,
  Form,
  FormLine,
  DaysOfWeek,
  Day,
  Line,
  RouteNum,
  ButtonBox,
  Button,
  ErrorMessage,
  RadioBox,
  RadioTypeRoute,
} from './styles';
import { useToast } from '../../../hooks/ToastContext';
import api from '../../../config/api';
import { Routes } from '../../../types/Routes';
import SelectInput from '../../Select';

type ModalProps = {
  visible: boolean;
  closeModal(): void;
  setRoutes: React.Dispatch<React.SetStateAction<Routes[]>>;
};

type DataFormSubmit = {
  respRoute: string;
  routeType: string;
  routeDayType: number;
  daysWeek: string[];
  status: boolean;
  routeNum: string;
};

const schema = yup.object().shape({
  respRoute: yup.string().required('* o respisável da rota é obrigatório'),
  routeType: yup.string().nullable().required('* o tipo de rota é obrigatório'),
  routeDayType: yup.number().required('* o tipo da rota é obrigatório'),
  daysWeek: yup
    .array()
    .nullable()
    .min(1)
    .required('* os dias da semana são obrigatórios'),
  status: yup.boolean(),
  routeNum: yup.string().required('* o número da rota é obrigatório'),
});

const CreateRouteModal: React.FC<ModalProps> = ({
  visible,
  closeModal,
  setRoutes,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<DataFormSubmit>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const handleOnSubmit = async (data: DataFormSubmit) => {
    try {
      const response = await api.post('/routes', {
        ...data,
        daysWeek: data.daysWeek.sort().join(),
        routeDayType: Number(data.routeDayType),
      });

      addToast({
        type: 'success',
        title: 'Criação de Rota',
        description: { code: 800 },
      });
      setRoutes((preState) => [...preState, response.data]);
      reset();
      setIsLoading(false);
      closeModal();
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na criação da rota',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  return (
    <Container visible={visible}>
      <Modal>
        <Form id="add_route" onSubmit={handleSubmit(handleOnSubmit)}>
          <FormLine>
            <RouteNum type="text" {...register('routeNum')} />
            <ErrorMessage>{errors.routeNum?.message}</ErrorMessage>
          </FormLine>
          <FormLine>
            <Input
              width="100%"
              title="Responsável da Rota"
              type="text"
              borderType
              {...register('respRoute')}
              error={errors.respRoute?.message}
            />
          </FormLine>
          <FormLine>
            <h1>Tipo de Rota</h1>
            <RadioBox>
              <RadioTypeRoute>
                <img src={pt} alt="Bandeira de Portugal" />
                <CheckBox
                  width="auto"
                  title=""
                  type="radio"
                  value="pt"
                  {...register('routeType')}
                  error={errors.routeType?.message}
                />
              </RadioTypeRoute>
              <RadioTypeRoute>
                <img src={uk} alt="Bandeira da Ucrania" />
                <CheckBox
                  width="auto"
                  title=""
                  type="radio"
                  value="uk"
                  {...register('routeType')}
                  error={errors.routeType?.message}
                />
              </RadioTypeRoute>
            </RadioBox>
            <ErrorMessage>{errors.routeType?.message}</ErrorMessage>
          </FormLine>

          <Line />
          <FormLine>
            <h1>Recorrência da rota</h1>
            <Controller
              control={control}
              name="routeDayType"
              render={({ field: { onChange, value } }) => (
                <SelectInput
                  borderType
                  title=""
                  onChange={onChange}
                  value={String(value)}
                  error={errors.routeDayType?.message}
                  options={[
                    { value: '7', label: 'semanal' },
                    { value: '15', label: 'quinzenal' },
                    { value: '30', label: 'mansal' },
                  ]}
                />
              )}
            />
          </FormLine>
          <FormLine>
            <h1>Estado da Rota</h1>
            <CheckBox
              width="auto"
              title="Ativa"
              type="checkbox"
              {...register('status')}
              error={errors.status?.message}
            />
          </FormLine>
          <Line />
          <FormLine>
            <h1>Dias da Rota</h1>
            <DaysOfWeek>
              <Day>
                <label htmlFor="segunda">
                  <input
                    {...register('daysWeek')}
                    type="checkbox"
                    id="segunda"
                    value="1"
                  />
                  <span>Segunda</span>
                </label>
              </Day>
              <Day>
                <label htmlFor="terca">
                  <input
                    {...register('daysWeek')}
                    type="checkbox"
                    id="terca"
                    value="2"
                  />
                  <span>Terça</span>
                </label>
              </Day>
              <Day>
                <label htmlFor="quarta">
                  <input
                    {...register('daysWeek')}
                    type="checkbox"
                    id="quarta"
                    value="3"
                  />
                  <span>Quarta</span>
                </label>
              </Day>
              <Day>
                <label htmlFor="quinta">
                  <input
                    {...register('daysWeek')}
                    type="checkbox"
                    id="quinta"
                    value="4"
                  />
                  <span>Quinta</span>
                </label>
              </Day>
              <Day>
                <label htmlFor="sexta">
                  <input
                    {...register('daysWeek')}
                    type="checkbox"
                    id="sexta"
                    value="5"
                  />
                  <span>Sexta</span>
                </label>
              </Day>
              <Day>
                <label htmlFor="sabado">
                  <input
                    {...register('daysWeek')}
                    type="checkbox"
                    id="sabado"
                    value="6"
                  />
                  <span>Sábado</span>
                </label>
              </Day>
              <Day>
                <label htmlFor="domingo">
                  <input
                    {...register('daysWeek')}
                    type="checkbox"
                    id="domingo"
                    value="0"
                  />
                  <span>Domingo</span>
                </label>
              </Day>
            </DaysOfWeek>
            <ErrorMessage>{errors.daysWeek?.message}</ErrorMessage>
          </FormLine>
          <ButtonBox>
            <Button
              type="button"
              color="#007970"
              bgcolor={isLoading ? '#10d5c2' : '#3cf0de'}
              bghover="#10d5c2"
              onClick={closeModal}
              disabled={isLoading}
            >
              <img src={chevron} alt="Icon de seta back" />
              Voltar
            </Button>
            {isLoading ? (
              <Button
                form="add_route"
                color="#3cf0de"
                bgcolor={isLoading ? '#00665f' : '#007970'}
                bghover="#00665f"
                disabled={isLoading}
              >
                <img src={loading} alt="Icon de desquete save" />a carregar...
              </Button>
            ) : (
              <Button
                form="add_route"
                type="submit"
                color="#3cf0de"
                bgcolor="#007970"
                bghover="#00665f"
              >
                <img src={save} alt="Icon de desquete save" />
                Guardar
              </Button>
            )}
          </ButtonBox>
        </Form>
      </Modal>
    </Container>
  );
};

export default CreateRouteModal;
