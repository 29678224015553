/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useToast } from '../../../hooks/ToastContext';
import api from '../../../config/api';

import BackButton from '../../../components/BackButton';
import Menu from '../../../components/Menu';
import UserData from '../../../components/UserData';

import {
  Container,
  Widget,
  Form,
  Box,
  Line,
  Column,
  Button,
  ButtonBox,
} from './styles';

import SupportCard from '../../../components/Supports/Support';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';

import loading from '../../../assets/loading03.svg';
import SelectInput from '../../../components/Select';
import { Support } from '../../../types/Support';

const schema = yup.object().shape({
  support_resp: yup.string().required('* é obrigatório preencher este campo!'),
  description: yup.string().required('* é obrigatório preencher este campo!'),
  social_tax: yup.string().required('* é obrigatório preencher este campo!'),
  type: yup.string().required('* é obrigatório preencher este campo!'),
  value: yup.string().required('* é obrigatório preencher este campo!'),
  date: yup.string().required('* é obrigatório preencher este campo!'),
});

type DataProps = {
  id: string;
  active: boolean;
};

type DataFormSub = {
  support_resp: string;
  description: string;
  social_tax: string;
  type: string;
  value: string;
  date: string;
};

const SupportsCreate: React.FC = () => {
  const [supports, setSupports] = useState<Support[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();
  const location = useLocation<DataProps>();

  const userId = location.state ? location.state.id : null;
  const active = location.state && location.state.active;

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<DataFormSub>({
    resolver: yupResolver(schema),
  });

  const getSupportData = async () => {
    try {
      const response = await api.get(`support/${userId}`);

      setSupports(response.data);
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro a obter dados do apoio!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  const handleSubmitForm = async (data: DataFormSub) => {
    try {
      setIsLoading(true);

      const response = await api.post(`/support/${userId}`, {
        ...data,
        type: Number(data.type),
      });

      if (response) {
        addToast({
          type: 'success',
          title: 'Apoio!',
          description: { code: 402 },
        });
        setSupports((prevState) => [...prevState, response.data]);
        reset({
          support_resp: '',
          description: '',
          date: '',
          type: '',
          value: '',
        });
        setIsLoading(false);
      }
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro na adição de apoio!',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSupportData();
  }, []);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <BackButton
            url={{
              pathname: '/utentes/ver',
              state: {
                id: userId,
              },
            }}
          />
          <Form>
            <UserData userId={userId || ''} />
            <Box>
              <h1>Apoios</h1>
              {supports.map((support) => (
                <SupportCard
                  key={support.id}
                  support={support}
                  active={active}
                />
              ))}
              {active && (
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <Line>
                    <Column>
                      <Line>
                        <Input
                          width="72%"
                          title="Responsável"
                          borderType
                          type="text"
                          {...register('support_resp')}
                          error={errors.support_resp?.message}
                        />
                        <Controller
                          control={control}
                          name="type"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <SelectInput
                                title="Tipo de Apoio"
                                {...register('type')}
                                onChange={onChange}
                                value={value || '1'}
                                width="25%"
                                borderType
                                error={errors.type?.message}
                                options={[
                                  { value: '1', label: 'Jurídico' },
                                  { value: '2', label: 'Emprego' },
                                  { value: '3', label: 'Bens' },
                                  { value: '4', label: 'Saúde' },
                                  { value: '5', label: 'Psicólogo' },
                                  { value: '6', label: 'Serviços' },
                                  { value: '7', label: 'Subsídios' },
                                ]}
                              />
                            );
                          }}
                        />
                      </Line>
                      <Line>
                        <Input
                          width="30%"
                          title="Data do apoio"
                          borderType
                          type="date"
                          {...register('date')}
                          error={errors.date?.message}
                        />
                        <Input
                          width="25%"
                          title="Taxa Social (€)"
                          borderType
                          type="number"
                          step=".01"
                          textAlign="right"
                          {...register('social_tax')}
                          error={errors.social_tax?.message}
                        />
                        <Input
                          width="15%"
                          title="Valor (€)"
                          borderType
                          type="number"
                          step=".01"
                          textAlign="right"
                          {...register('value')}
                          error={errors.value?.message}
                        />
                      </Line>
                    </Column>
                    <TextArea
                      width="50%"
                      title="Descrição"
                      {...register('description')}
                      error={errors.description?.message}
                    />
                  </Line>

                  <ButtonBox>
                    {isLoading ? (
                      <Button
                        color="#3cf0de"
                        bgcolor={isLoading ? '#00665f' : '#007970'}
                        bghover="#00665f"
                        disabled={isLoading}
                      >
                        <img src={loading} alt="Icon de desquete save" />a
                        carregar...
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        color="#3cf0de"
                        bgcolor="#007970"
                        bghover="#00665f"
                      >
                        Adicionar
                      </Button>
                    )}
                  </ButtonBox>
                </form>
              )}
            </Box>
          </Form>
        </Widget>
      </Container>
    </>
  );
};

export default SupportsCreate;
