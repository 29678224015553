import styled from 'styled-components';

export const DashCard = styled.div`
  width: 450px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  width: 450px;
  height: 140px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 121, 112, 0.2);
  box-shadow: 5px 5px 15px -8px rgba(0, 121, 112, 0.5);
  color: #007970;
  transition: 0.2s background-color;
`;

export const ImageCard = styled.span`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  > img {
    width: 35px;
  }
  > p {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  padding-top: 0;
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
`;

export const InfoCardCircle = styled.div`
  position: relative;
  height: 40px;
  padding: 25px;
  border-radius: 50%;
  color: #007970;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;

  img {
    width: 35px;
  }
`;

export const InfoCardTitle = styled.p`
  color: #007970;
  font-weight: bold;
  font-size: 1.4rem;
`;

export const Dates = styled.div`
  width: 370px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  > div {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      font-size: 14px;
      margin-right: 5px;
    }

    > input {
      padding: 0.9rem;
      color: #007970;
      font-size: 1.4rem;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      &::-webkit-input-placeholder {
        color: rgba(0, 121, 112, 0.8);
      }
    }
  }
`;

export const ErrorDate = styled.p`
  color: #ff5050;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;
