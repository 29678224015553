import styled from 'styled-components';

type LineProps = {
  bg: string;
};

export const Line = styled.tr<LineProps>`
  background-color: ${(props) => props.bg};
`;

export const Index = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    animation: alert 2s linear infinite;
  }

  @keyframes alert {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const CheckBoxPrint = styled.div`
  width: 100%;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;

  span {
    background: #ccc !important;
  }

  label {
    position: relative;
    font-size: 1.8rem;
    color: #007970;
    padding-right: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      span {
        background-color: #aaa !important;
      }
    }
  }

  input:checked ~ span:after {
    display: block;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    background-color: #007970 !important;
  }

  span {
    margin-left: 0.5rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-right: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      margin-top: -2px;
      display: none;
    }
  }

  span::after {
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  p {
    position: absolute;
    color: #ff5050;
    font-size: 9px;
    bottom: 0;
    left: 1rem;
  }
`;
