import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  justify-content: center;
`;

export const Widget = styled.div`
  width: 140rem;
  max-width: 140rem;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 15rem;
  }

  p {
    font-size: 2.2rem;
    position: absolute;
    bottom: 1rem;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 2rem;
  justify-items: center;
`;

export const DashCard = styled.div`
  width: 450px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.button`
  width: 450px;
  height: 140px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 121, 112, 0.2);
  box-shadow: 5px 5px 15px -8px rgba(0, 121, 112, 0.5);
  color: #007970;
  transition: 0.2s background-color;

  &:hover {
    background-color: rgba(0, 204, 190, 0.3);
    cursor: pointer;
  }
`;

export const ImageCard = styled.span`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 10px;

  > img {
    width: 35px;
  }
  > p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: bold;
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  padding-top: 0;
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
`;

export const InfoCardCircle = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  padding: 25px;
  border-radius: 50%;
  color: #007970;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;

  > img {
    width: 3rem;
  }
`;

export const InfoCardTitle = styled.p`
  color: #007970;
  font-weight: bold;
  font-size: 1.6rem;
`;
