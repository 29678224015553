import styled from 'styled-components';

type TurnCardProps = {
  status: boolean;
};

export const Container = styled.div`
  width: 100vw;
  padding-top: 7rem;
  display: flex;
  justify-content: center;
`;

export const Widget = styled.div`
  width: 140rem;
  max-width: 140rem;
`;

export const Top = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 3rem;
    font-weight: bold;
  }

  button {
    margin-left: 1.5rem;
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 3rem;
      height: 3rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }

  button + button {
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  align-items: center;

  input {
    border: 2px solid #007970;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 30rem;
    color: #007970;
    font-size: 1.6rem;
    &::-webkit-input-placeholder {
      color: rgba(0, 121, 112, 0.8);
    }
  }

  div {
    margin-left: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #007970;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    img {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:hover {
      cursor: pointer;
      background: #00665f;
    }
  }
`;

export const Loading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 15rem;
  }

  p {
    font-size: 2.2rem;
    position: absolute;
    bottom: 1rem;
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 15px 15px;
`;

export const TurnCard = styled.div<TurnCardProps>`
  position: relative;
  width: 200px;
  height: 280px;
  box-shadow: 5px 5px 15px -8px rgba(0, 121, 112, 0.5);
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  flex-direction: column;
  background-color: ${(props) => (props.status ? '#fff' : '#fff1f1')};

  > img {
    position: absolute;
    width: 2.2rem;
    height: 1.4rem;
    top: 0;
    left: 0;
    margin-top: 15px;
    margin-left: 30px;
  }
`;

export const TurnCardCircule = styled.span`
  position: relative;
  width: 40px;
  height: 40px;
  padding: 50px;
  border-radius: 50%;
  background: #00ccbe;
  color: #007970;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
`;

export const TurnCardCirculeEdit = styled.button`
  position: absolute;
  bottom: -1rem;
  right: -1rem;
  border: none;
  background: #3cf0de;
  width: 4rem;
  height: 4rem;
  margin-right: 5px;
  margin-bottom: 8px;
  border-radius: 50%;

  > img {
    width: 2.5rem;
    height: 2.5rem;
  }

  &:hover {
    background: #10d5c2;
  }
`;

export const TurnCardBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TurnCardBodyBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const TurnCardBodyButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`;

export const TurnCardBodyTittle = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const TurnCardBodyText = styled.span`
  font-size: 12px;
`;

export const TurnCardBodyButton = styled.button`
  font-size: 12px;
  font-weight: bold;
  color: #3cf0de;
  background: #007970;
  border: none;
  padding: 3px;
  padding-left: 40px;
  padding-right: 40px;
  transition: background 0.2s;

  &:hover {
    background: #00665f;
  }
`;

export const TurnNum = styled.div`
  position: absolute;
  top: 0;
  right: -1rem;
  border: none;
  background: #12edd7;
  width: 3rem;
  height: 3rem;
  margin-right: 5px;
  margin-bottom: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: normal;

  &:hover {
    cursor: pointer;
  }
`;
