import styled from 'styled-components';

type SelectProps = {
  isOpen: boolean;
};

export const Container = styled.div<SelectProps>`
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 999;
  background-color: #fff;
  top: 0;
  left: 0;
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 10px;
  margin-right: 5px;
  margin-top: 5px;
  background-color: transparent;
  border: 0;
  opacity: 0.5;
  transition: 0.2s opacity;

  &:hover {
    opacity: 1;
  }

  p {
    font-size: 3rem;
    color: #007970;
  }
`;

export const Loading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 15rem;
  }

  p {
    font-size: 2.2rem;
    position: absolute;
    bottom: 1rem;
  }
`;
