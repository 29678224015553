/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-duplicates */

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../config/api';
import NewModal from '../../Modal';
import { useToast } from '../../../hooks/ToastContext';
import { Order } from '../../../types/Order';
import OrderModalConfirm from '../OrderModalConfirm';
import OrderModalNotDelivered from '../OrderModalNotDelivered';

import note_text from '../../../assets/text.svg';

import {
  Back,
  Modal,
  TopBox,
  TopText,
  List,
  ListProduct,
  Product,
  ProductInfo,
  ProductText,
  ProductMult,
  Count,
  CountData,
  CountText,
  CountLine,
  Line,
  Close,
  ButtonBox,
  Button,
  NoteButton,
  Note,
  ProductCreditation,
} from './styles';

type OrderModalProps = {
  orderId: string | null;
  close(): void;
  reload(): Promise<void>;
};

const OrderModal: React.FC<OrderModalProps> = ({ orderId, close, reload }) => {
  const [order, setOrder] = useState({} as Order);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [notDeliveredModalVisible, setNotDeliveredModalVisible] =
    useState(false);
  const [noteDisplay, setNoteDisplay] = useState(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => null);
  const [confirmCancelAction, setConfirmCancelAction] = useState<
    (data: { note: string }) => Promise<void>
  >(async () => {
    await null;
  });
  const [modalTitle, setModalTitle] = useState('');

  const { addToast } = useToast();

  const hanfleConfirmModal = useCallback((text: string, action: () => void) => {
    setConfirmModalVisible(true);
    setModalTitle(text);
    setConfirmAction(() => action);
  }, []);

  const hanfleConfirmCancelOrderModal = useCallback(
    (text: string, action: (data: { note: string }) => Promise<void>) => {
      setNotDeliveredModalVisible(true);
      setModalTitle(text);
      setConfirmCancelAction(() => action);
    },
    [],
  );

  const handleAcceptOrder = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.put(`order/accept/${order.id}`);

      reload();

      addToast({
        type: 'success',
        title: 'Encomenda Aceite',
        description: { code: 756 },
      });
      setIsLoading(false);
      close();
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro ao aceitar a encomenda',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  }, [reload, order, addToast, close]);

  const handleRefuseOrder = useCallback(
    async (data: { note: string }) => {
      setIsLoading(true);
      try {
        await api.put(`order/refuse/${order.id}`, data);

        reload();

        addToast({
          type: 'success',
          title: 'Encomenda Cancelada',
          description: { code: 755 },
        });
        setIsLoading(false);
        close();
      } catch (e: any) {
        addToast({
          type: 'error',
          title: 'Erro ao cancelar a encomenda',
          description:
            e.response && e.response.data ? e.response.data : { code: 1000 },
        });
        setIsLoading(false);
      }
    },
    [reload, order, addToast, close],
  );

  const handleDeliveredOrder = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.put(`order/delivered/${order.id}`);

      reload();

      addToast({
        type: 'success',
        title: 'Encomenda Entregue',
        description: { code: 757 },
      });
      setIsLoading(false);
      close();
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Erro ao processar encomenda',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
      setIsLoading(false);
    }
  }, [reload, order, addToast, close]);

  const handleNotDeliveredOrder = useCallback(
    async (data: { note: string }) => {
      setIsLoading(true);
      try {
        await api.put(`order/notdelivered/${order.id}`, data);

        reload();

        addToast({
          type: 'success',
          title: 'Encomenda Não Entregue',
          description: { code: 758 },
        });
        setIsLoading(false);
        close();
      } catch (e: any) {
        addToast({
          type: 'error',
          title: 'Erro ao processar encomenda',
          description:
            e.response && e.response.data ? e.response.data : { code: 1000 },
        });
        setIsLoading(false);
      }
    },
    [reload, order, addToast, close],
  );

  const handleOrderStatus = useCallback((status: string | null) => {
    switch (status) {
      case 'pending':
        return 'Pendente para aprovação';
      case 'accepted':
        return 'Aceite';
      case 'refused':
        return 'Cancelada';
      case 'not delivered':
        return 'Não entregue';
      case 'delivered':
        return 'Entregue';
      default:
        return 'Sem encomendas';
    }
  }, []);

  const getOrder = useCallback(
    async (id: string) => {
      setIsLoading(true);
      try {
        const response = await api.get(`/orders/${id}`);
        setOrder(response.data);

        setIsLoading(false);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Erro a obter dados encomenda',
          description: { code: 751 },
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId, getOrder]);

  return (
    <Back>
      {orderId && order.id && (
        <>
          <Modal>
            <TopBox>
              <h1>Encomenda</h1>
              <TopText>Referência: {order.intRef}</TopText>
              <TopText>Agregado: {order.household.name}</TopText>

              <TopText>
                Data de distribuição:{' '}
                {order.distributionDate
                  ? format(
                      parseISO(order.distributionDate),
                      `dd 'de' MMMM 'de' yyyy `,
                      { locale: pt },
                    )
                  : 'Sem data'}
              </TopText>
              <TopText>Estado: {handleOrderStatus(order.status)}</TopText>
              {order.reviewedByData && (
                <TopText>Revisto por: {order.reviewedByData.name}</TopText>
              )}
              <List>
                <h1>Produtos:</h1>
                <ListProduct>
                  {order.orderItems.map((item) => (
                    <Product key={item.id}>
                      <ProductInfo>
                        <ProductText>{item.amount}</ProductText>
                        <ProductMult>&#x2715;</ProductMult>
                        <div>
                          <ProductText>{item.name}</ProductText>
                          {item.creditaionStatus && (
                            <ProductCreditation>
                              Creditação de {item.creditaionAmount}un
                            </ProductCreditation>
                          )}
                        </div>
                      </ProductInfo>
                      <ProductText>
                        {(item.amount * item.value).toFixed(2)} sm
                        {item.creditaionStatus && (
                          <ProductCreditation>
                            {(item.creditaionAmount * item.value).toFixed(2)} sm
                          </ProductCreditation>
                        )}
                      </ProductText>
                    </Product>
                  ))}
                </ListProduct>
                <Count>
                  <CountData>
                    <CountText>
                      Saldo Antes Encomenda:&nbsp;&nbsp;{' '}
                      {order.transaction &&
                        order.transaction.initialValue.toFixed(2)}
                    </CountText>

                    <CountText>
                      Valor Total da Encomenda:&nbsp; &nbsp;-{' '}
                      {order.transaction &&
                        order.transaction.transactionValue.toFixed(2)}
                    </CountText>

                    <CountLine>
                      <Line />
                    </CountLine>
                    <CountText>
                      Saldo Após Encomenda:&nbsp; &nbsp; &nbsp;
                      {order.transaction &&
                        order.transaction.finalValue.toFixed(2)}
                    </CountText>
                  </CountData>
                </Count>
              </List>
            </TopBox>
            <Close title="Fechar" onClick={close}>
              <p>&#x2715;</p>
            </Close>
            {order.status === 'pending' && (
              <ButtonBox>
                <Button
                  type="button"
                  color="#3cf0de"
                  bgcolor={isLoading ? '#ff3333' : '#ff6666'}
                  bghover="#ff3333"
                  onClick={() =>
                    hanfleConfirmCancelOrderModal(
                      'Tem a certeza que quer cancelar esta encomenda?',
                      handleRefuseOrder,
                    )
                  }
                  disabled={isLoading}
                >
                  Cancelar Encomenda
                </Button>

                <Button
                  type="button"
                  color="#3cf0de"
                  bgcolor={isLoading ? '#00665f' : '#007970'}
                  bghover="#00665f"
                  onClick={() =>
                    hanfleConfirmModal(
                      'Tem a certeza que quer aceitar esta encomenda?',
                      handleAcceptOrder,
                    )
                  }
                  disabled={isLoading}
                >
                  Aceitar Encomenda
                </Button>
              </ButtonBox>
            )}

            {order.status === 'accepted' && (
              <ButtonBox>
                <Button
                  type="button"
                  color="#3cf0de"
                  bgcolor={isLoading ? '#ff3333' : '#ff6666'}
                  bghover="#ff3333"
                  onClick={() =>
                    hanfleConfirmCancelOrderModal(
                      'Tem a certeza que quer dar esta encomenda como não entregue?',
                      handleNotDeliveredOrder,
                    )
                  }
                  disabled={isLoading}
                >
                  Não Entregue
                </Button>

                <Button
                  type="button"
                  color="#3cf0de"
                  bgcolor={isLoading ? '#00665f' : '#007970'}
                  bghover="#00665f"
                  onClick={() =>
                    hanfleConfirmModal(
                      'Tem a certeza que quer dar esta encomenda como entregue?',
                      handleDeliveredOrder,
                    )
                  }
                  disabled={isLoading}
                >
                  Entregue
                </Button>
              </ButtonBox>
            )}
            {(order.status === 'not delivered' ||
              order.status === 'refused') && (
              <>
                <NoteButton
                  onClick={() => setNoteDisplay((prevState) => !prevState)}
                >
                  <img src={note_text} alt="Notas" />
                </NoteButton>
                {noteDisplay && (
                  <Note>
                    <h1>Razão:</h1>
                    {order.orderNotes.length > 0 ? (
                      <>
                        <p>{order.orderNotes[0].note}</p>
                        <span>
                          {format(
                            parseISO(order.orderNotes[0].createdAt),
                            `dd'/'MM'/'yyyy' - 'HH:mm:ss`,
                            { locale: pt },
                          )}
                        </span>
                      </>
                    ) : (
                      <p>Sem razão</p>
                    )}
                  </Note>
                )}
              </>
            )}
          </Modal>
          {confirmModalVisible && (
            <NewModal visible={confirmModalVisible}>
              <OrderModalConfirm
                close={() => setConfirmModalVisible(false)}
                confirm={confirmAction}
                title={modalTitle}
                isLoading={isLoading}
              />
            </NewModal>
          )}
          {notDeliveredModalVisible && (
            <NewModal visible={notDeliveredModalVisible}>
              <OrderModalNotDelivered
                title="Tem a certeza que quer dar esta encomenda como não entregue?"
                close={() => setNotDeliveredModalVisible(false)}
                isLoading={isLoading}
                handleOnSubmit={confirmCancelAction}
              />
            </NewModal>
          )}
        </>
      )}
    </Back>
  );
};

export default OrderModal;
