/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-duplicates */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import Td from '../../Table/Td';

import { Line, Index, CheckBoxPrint } from './styles';

import view from '../../../assets/view.svg';
import { Order } from '../../../types/Order';

type OrderLinePropsReq = {
  order: Order;
  index: number;
  action: () => Promise<void>;
  handleCheckToPrint: (status: boolean, value: string) => void;
};

const OrdertLine: React.FC<OrderLinePropsReq> = ({
  order,
  index,
  action,
  handleCheckToPrint,
}) => {
  const handleOrderStatus = useCallback((status: string | null) => {
    switch (status) {
      case 'pending':
        return 'Pendente para aprovação';
      case 'accepted':
        return 'Aceite';
      case 'refused':
        return 'Cancelada';
      case 'not delivered':
        return 'Não entregue';
      case 'delivered':
        return 'Entregue';
      default:
        return 'Sem encomendas';
    }
  }, []);

  const handleOrderStatusColor = useCallback((status: string | null) => {
    switch (status) {
      case 'pending':
        return '#fff';
      case 'accepted':
        return '#ffffe6';
      case 'refused':
        return '#ffe6e6';
      case 'not delivered':
        return '#ffe6e6';
      case 'delivered':
        return '#99ffcc';
      default:
        return 'Sem encomendas';
    }
  }, []);

  return (
    <Line bg={handleOrderStatusColor(order.status)}>
      <Td talign="center" width="5%">
        <Index>
          <CheckBoxPrint>
            <label>
              <input
                type="checkbox"
                value={order.id}
                onChange={(e) =>
                  handleCheckToPrint(
                    e.currentTarget.checked,
                    e.currentTarget.value,
                  )
                }
              />
              <span />
              {index}
            </label>
          </CheckBoxPrint>
        </Index>
      </Td>
      <Td talign="center" width="15%">
        {order.intRef}
      </Td>
      <Td talign="center" width="25%">
        {order.household.name}
      </Td>
      <Td talign="center" width="10%">
        {handleOrderStatus(order.status)}
      </Td>
      <Td talign="center" width="10%">
        {order.value.toFixed(2)}
        <small>sm</small>
      </Td>
      <Td talign="center" width="20%">
        {format(parseISO(order.createdAt), `dd'/'MM'/'yyyy' - 'HH:mm:ss`, {
          locale: pt,
        })}
      </Td>
      <Td talign="center" width="10%">
        {order.distributionDate &&
          format(parseISO(order.distributionDate), `dd'/'MM'/'yyyy'`, {
            locale: pt,
          })}
      </Td>

      <Td talign="center" width="5%">
        <div>
          <button
            type="button"
            title="ver detalhes da encomenda"
            onClick={action}
          >
            <img src={view} alt="icon de um lápis" />
          </button>
        </div>
      </Td>
    </Line>
  );
};

export default OrdertLine;
