import styled from 'styled-components';

export const DashCard = styled.div`
  width: 450px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  width: 450px;
  height: 140px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 121, 112, 0.2);
  box-shadow: 5px 5px 15px -8px rgba(0, 121, 112, 0.5);
  color: #007970;
  transition: 0.2s background-color;
`;

export const ImageCard = styled.span`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 10px;

  > img {
    width: 35px;
  }
  > p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: bold;
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  padding-top: 0;
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
`;

export const InfoCardCircle = styled.div`
  position: relative;
  padding: 15px;
  width: 100%;
  color: #007970;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  font-weight: bold;

  img {
    width: 35px;
  }
`;

export const InfoCardTitle = styled.p`
  color: #007970;
  font-weight: bold;
  font-size: 1.4rem;
`;

export const SelectBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 30px;
  height: 30px;
  margin-bottom: 10px;

  select {
    width: 100%;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #007970;
    text-align: center;
    font-size: 1.6rem;
    color: #007970;
  }

  span {
    font-size: 1.6rem;
  }
`;
