/* eslint-disable @typescript-eslint/no-explicit-any */
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { Container, Close, Loading } from './styles';
import loading from '../../../assets/loading01.svg';

const styles = StyleSheet.create({
  view: {
    width: '100vw',
    height: '100vh',
  },
});

type ModalPDFProps = {
  isOpen: boolean;
  isLoading: boolean;

  onClose: () => void;
  children: any;
};

const PDFModal: React.FC<ModalPDFProps> = ({
  isOpen,
  onClose,
  children,
  isLoading,
}) => {
  return (
    <Container isOpen={isOpen}>
      <Close title="Fechar" onClick={onClose}>
        <p>&#x2715;</p>
      </Close>
      {isLoading ? (
        <Loading>
          <img src={loading} alt="Imagem de Loading" />
          <p>A carregar...</p>
        </Loading>
      ) : (
        <PDFViewer style={styles.view}>{children}</PDFViewer>
      )}
    </Container>
  );
};

export default PDFModal;
