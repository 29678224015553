import React, { useEffect, useState, useCallback } from 'react';
import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';
import File from '../../components/PdfFiles/PdfRoutes';

import Menu from '../../components/Menu';

import add from '../../assets/add.svg';
import print from '../../assets/print.svg';
import search from '../../assets/search.svg';
import loading from '../../assets/loading01.svg';
import edit from '../../assets/edit.svg';
import pt from '../../assets/pt.svg';
import uk from '../../assets/uk.svg';

import {
  Container,
  Widget,
  Top,
  Title,
  Search,
  Loading,
  CardGrid,
  TurnCard,
  TurnCardCircule,
  TurnCardCirculeEdit,
  TurnCardBody,
  TurnCardBodyBox,
  TurnCardBodyText,
  TurnCardBodyTittle,
  TurnCardBodyButtonBox,
  TurnCardBodyButton,
  TurnNum,
} from './styles';

import { Routes } from '../../types/Routes';
import CreateRouteModal from '../../components/RoutesModal/CreateRouteModal';
import EditRouteModal from '../../components/RoutesModal/EditRouteModal';
import ViewRouteModal from '../../components/RoutesModal/ViewRouteModal';
import RouteWeek from '../../components/RoutesModal/RouteWeek';
import PDFModal from '../../components/PdfFiles/Modal';

let debounce: NodeJS.Timeout;

const RoutesPage: React.FC = () => {
  const [routes, setRoutes] = useState([] as Routes[]);
  const [routesToDisplay, setRoutesToDisplay] = useState([] as Routes[]);

  const [addRouteModalVisible, setAddRouteModalVisible] = useState(false);
  const [editRouteModalVisible, setEditRouteModalVisible] = useState(false);
  const [viewRouteModalVisible, setViewRouteModalVisible] = useState(false);
  const [printModal, setPrintModal] = useState(false);

  const [routeDataToEdit, setRouteDataToEdit] = useState({} as Routes);
  const [idRoute, setIdRoute] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const { addToast } = useToast();

  const handleEditRoute = (route: Routes) => {
    setRouteDataToEdit(route);
    setEditRouteModalVisible(true);
  };

  const getRoutes = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/routes');

      setRoutes(
        response.data.sort((a: Routes, b: Routes) =>
          a.routeNum.localeCompare(b.routeNum, undefined, { numeric: true }),
        ),
      );
      setIsLoading(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter rotas',
        description: { code: 805 },
      });
    }
  }, [addToast]);

  const handleSeach = (e: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(debounce);
    e.preventDefault();

    const searchText = e.currentTarget.value.toLowerCase();
    searchText
      ? (debounce = setTimeout(() => {
          const arrayTemp = routes.filter((route) => {
            const daysOfRoute = route.daysWeek
              .split(',')
              .map((day) => {
                switch (day) {
                  case '0':
                    return 'Domingo';
                  case '1':
                    return 'Segunda';
                  case '2':
                    return 'Terça';
                  case '3':
                    return 'Quarta';
                  case '4':
                    return 'Quinta';
                  case '5':
                    return 'Sexta';
                  case '6':
                    return 'Sábado';
                  default:
                    return 'Nenhum';
                }
              })
              .join(', ');

            return (
              daysOfRoute.toLowerCase().includes(searchText) ||
              route.respRoute.toLowerCase().includes(searchText) ||
              route.routeType.toLowerCase().includes(searchText) ||
              String(route.routeNum).toLowerCase().includes(searchText) ||
              (route.status && searchText.toLowerCase() === 'ativa') ||
              (!route.status && searchText.toLowerCase() === 'inativa') ||
              route.turns.find(
                (t) =>
                  t.household &&
                  t.household.name.toLowerCase().includes(searchText),
              ) ||
              route.turns.find(
                (t) =>
                  t.household &&
                  t.household.user.location.toLowerCase().includes(searchText),
              )
            );
          });
          setRoutesToDisplay(arrayTemp);
        }, 500))
      : (debounce = setTimeout(() => {
          setRoutesToDisplay(routes);
        }, 500));
  };

  const handleChangeRoutesData = useCallback(async () => {
    try {
      const response = await api.get('/routes');
      setRoutesToDisplay(
        response.data.sort((a: Routes, b: Routes) =>
          a.routeNum.localeCompare(b.routeNum, undefined, { numeric: true }),
        ),
      );
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter rotas',
        description: { code: 805 },
      });
    }
  }, [addToast]);

  useEffect(() => {
    getRoutes();
  }, [getRoutes]);

  useEffect(() => {
    handleChangeRoutesData();
  }, [routes, handleChangeRoutesData]);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Rotas</h1>
              <button
                type="button"
                title="Adicionar Rota"
                onClick={() => setAddRouteModalVisible(true)}
              >
                <img src={add} alt="plus" />
              </button>
              <button
                type="button"
                title="Imprimir Rotas"
                onClick={() => setPrintModal(true)}
              >
                <img src={print} alt="print" />
              </button>
            </Title>
            <RouteWeek />
            <Search>
              <input
                type="text"
                name="search"
                placeholder="Pesquisar..."
                onChange={handleSeach}
              />
              <div title="Pesquisar">
                <img src={search} alt="search" />
              </div>
            </Search>
          </Top>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <CardGrid>
              {routesToDisplay.map((route) => {
                const daysToDisplay = route.daysWeek.split(',').map((day) => {
                  switch (day) {
                    case '0':
                      return 'Domingo';
                    case '1':
                      return 'Segunda';
                    case '2':
                      return 'Terça';
                    case '3':
                      return 'Quarta';
                    case '4':
                      return 'Quinta';
                    case '5':
                      return 'Sexta';
                    case '6':
                      return 'Sábado';
                    default:
                      return 'Nenhum';
                  }
                });
                return (
                  <TurnCard status={route.status} key={route.id}>
                    {route.routeType === 'pt' ? (
                      <img src={pt} alt="Bandeira de Portugal" />
                    ) : (
                      <img src={uk} alt="Bandeira da Ucrania" />
                    )}
                    <TurnCardCircule>
                      <TurnNum
                        title={`Número de voltas: ${
                          route.turns ? route.turns.length : 0
                        }`}
                      >
                        {route.turns ? route.turns.length : 0}
                      </TurnNum>
                      {route.routeNum}
                      <TurnCardCirculeEdit
                        type="button"
                        onClick={() => handleEditRoute(route)}
                      >
                        <img src={edit} alt="Imagem de um lapis" />
                      </TurnCardCirculeEdit>
                    </TurnCardCircule>
                    <TurnCardBody>
                      <TurnCardBodyBox>
                        <TurnCardBodyTittle>
                          Responsável da Rota:{' '}
                        </TurnCardBodyTittle>
                        <TurnCardBodyText>{route.respRoute}</TurnCardBodyText>
                      </TurnCardBodyBox>
                      <TurnCardBodyBox>
                        <TurnCardBodyTittle>
                          Dias da Semana:{' '}
                        </TurnCardBodyTittle>
                        <TurnCardBodyText>
                          {daysToDisplay.join(', ')}
                        </TurnCardBodyText>
                      </TurnCardBodyBox>
                      <TurnCardBodyBox>
                        <TurnCardBodyTittle>Recorrência </TurnCardBodyTittle>
                        <TurnCardBodyText>
                          {route.routeDayType === 7 && 'semanal'}
                          {route.routeDayType === 15 && 'quinzenal'}
                          {route.routeDayType === 30 && 'mensal'}
                        </TurnCardBodyText>
                      </TurnCardBodyBox>
                      <TurnCardBodyButtonBox>
                        <TurnCardBodyButton
                          onClick={() => {
                            setIdRoute(route.id);
                            setViewRouteModalVisible(true);
                          }}
                        >
                          Ver detalhes
                        </TurnCardBodyButton>
                      </TurnCardBodyButtonBox>
                    </TurnCardBody>
                  </TurnCard>
                );
              })}
            </CardGrid>
          )}
          {addRouteModalVisible && (
            <CreateRouteModal
              visible
              closeModal={() => setAddRouteModalVisible(false)}
              setRoutes={setRoutes}
            />
          )}
          {editRouteModalVisible && (
            <EditRouteModal
              visible
              closeModal={() => setEditRouteModalVisible(false)}
              setRoutes={setRoutes}
              routeData={routeDataToEdit}
            />
          )}

          {viewRouteModalVisible && (
            <ViewRouteModal
              visible
              idRoute={idRoute}
              closeModal={() => setViewRouteModalVisible(false)}
              setRoutes={setRoutes}
            />
          )}
        </Widget>
        {printModal && (
          <PDFModal
            isOpen={printModal}
            onClose={() => {
              setPrintModal(false);
            }}
            isLoading={false}
          >
            <File routes={routesToDisplay} week={null} date={null} />
          </PDFModal>
        )}
      </Container>
    </>
  );
};

export default RoutesPage;
