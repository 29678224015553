/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-duplicates */
import React, { useState, useEffect, useCallback } from 'react';

import api from '../../../config/api';
import { useToast } from '../../../hooks/ToastContext';

import {
  DashCard,
  Card,
  Info,
  InfoCard,
  InfoCardCircle,
  SelectBox,
  ImageCard,
} from './styles';

const NationalityDashboard: React.FC = () => {
  const [dashboardInfo, setDashboardInfo] = useState<
    {
      label: string;
      num: number;
    }[]
  >([]);

  const [numSelect, setNumSelect] = useState(0);

  const { addToast } = useToast();

  const getDashboardInfo = useCallback(async () => {
    try {
      const response = await api.get(`/dashboard/nationality`);
      setDashboardInfo(response.data);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter dados',
        description: { code: 750 },
      });
    }
  }, [addToast]);

  useEffect(() => {
    getDashboardInfo();
  }, []);

  return (
    <DashCard>
      <Card>
        <ImageCard>
          <p>Nº Utentes por nacionalidade</p>
        </ImageCard>
        <Info>
          <InfoCard>
            <InfoCardCircle>
              {dashboardInfo && dashboardInfo.length > 0 && (
                <SelectBox>
                  <select
                    name="type"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setNumSelect(Number(e.currentTarget.value))
                    }
                  >
                    <option disabled selected>
                      Nacionalidade
                    </option>
                    {dashboardInfo.map((info) => (
                      <option key={info.label} value={info.num}>
                        {info.label}
                      </option>
                    ))}
                  </select>
                </SelectBox>
              )}
              {numSelect}
            </InfoCardCircle>
          </InfoCard>
        </Info>
      </Card>
    </DashCard>
  );
};

export default NationalityDashboard;
